import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const _frontmatter = {
  "date": "2017-06-20",
  "title": "Overcoming Fear and Doing Your Own Thing",
  "description": "making the jump to the entrepreneur life",
  "color": "#ffa4b1"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If you were a genie and could grant developers a single wish - any desire of their hearts - eight out of ten would tell you "I wish I could do my own thing." Most devs dream about having complete autonomy, creativity, and purpose by doing their own thing.`}</p>
    <h2>{`Do you really want it?`}</h2>
    <p>{`For `}<em parentName="p">{`years`}</em>{` I was a classic "wantrepreneur". That guy who's always talking about starting his own business but who spends most of his free time playing Starcraft.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Our actions always reveal our real values. `}<br /><br parentName="p"></br>{`
`}{`— Derek Sivers`}</p>
    </blockquote>
    <p>{`If this rings a bell and `}<a parentName="p" {...{
        "href": "https://sivers.org/arv"
      }}>{`your actions aren't lining up with your words`}</a>{`, it's possible that you don't actually `}<em parentName="p">{`want`}</em>{` to do your own thing. And that's OK! Absolutely nothing wrong with working with cool people at a 9 to 5.`}</p>
    <p>{`But if you `}<em parentName="p">{`do`}</em>{` want it, then there's really only one obstacle in your way: `}<strong parentName="p">{`fear`}</strong>{`.`}</p>
    <h2>{`The only thing holding you back`}</h2>
    <p>{`I'm speaking from experience on this one. As soon as I decided to get real about my goal to do my own thing, my brain helpfully provided a billion reasons why I shouldn't. Here are just a few that may sound familiar to you:`}</p>
    <h3>{`Don't have the money`}</h3>
    <p>{`You wonder how you'll pay the bills and feed yourself and your family. If you're being honest though you know you'd never actually let that happen and as developer you could pretty easily get `}<em parentName="p">{`some`}</em>{` kind of job. But you `}<em parentName="p">{`worry`}</em>{` it would be a waste to save up soooo much cash just to bootstrap something that doesn't pan out. And you `}<em parentName="p">{`dread`}</em>{` that future disappointment of failing at this dream you worked so hard just to get the opportunity to take a shot at!`}</p>
    <p className="note">
Note: I don't recommend quitting your day job until you're either making enough on the side already to cover all your expenses or you've saved up a full year's runway. More on that in a future post.
    </p>
    <h3>{`Don't have good enough ideas`}</h3>
    <p>{`"But what if my idea just plain sucks?" Well it just might. But how will you ever know if you don't ship it? An amazing idea that never ships is more tragic than a classic French film. Buying into this fear is choosing to experience failure in advance.`}</p>
    <h3>{`Spouse isn't on board`}</h3>
    <p>{`This is a big one. Gary Vaynerchuck (one of my heroes) makes the case that most of the time our primary fear is to `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=Ly_rnnJmyTg"
      }}>{`let down someone we love`}</a>{`. The reality is they probably believe in you a lot more than you do! After watching Gary's video I asked my wife Melissa if she would be supportive of me doing my own thing even if I crashed and burned. Her response was gold to me: "Oh my gosh honey I just want you to be happy and I've never doubted you for a second". Somehow I had let my fear convince me that failure at entrepreneurship would be failure at marriage, which raised the stakes to unreasonable levels.`}</p>
    <h3>{`What if I fail`}</h3>
    <p>{`People often point out "hey, the worst that could happen is you get another day job!". And while you agree with that assesment, the thought of failure still stings. You worry that people will somehow think lesser of you if you botch it.`}</p>
    <h3>{`My market is saturated`}</h3>
    <p>{`"So many people are making amazing things already! How can I compete with them?" Think about just one of the successful competitors in your space. Did the market have just one slot left before filling up and they took it? This is just that old fear again telling you you're not as good, not as smart, not as creative. Thanks a bunch, fear.`}</p>
    <h3>{`Too old`}</h3>
    <p>{`One of the saddest conversations I ever had was when I told a person about my upcoming adventure and at first he was smiling, reminiscing about a time ten years ago when he was about to try something similar. But his countenance fell as he recounted how he had decided to play it safe and stay at his "miserable job". He then sighed and repeated to me the words of his shoulder-dwelling fear angel: "Anyway, I'm too old for that now".`}</p>
    <h3>{`Hard to give up something good`}</h3>
    <p>{`Isn't it funny how badly we cling to things once we think we're about to give them up? Even things we `}<em parentName="p">{`don't even like`}</em>{`. If I know I'm going to start a diet tomorrow suddenly those stale cheetos in the pantry look `}<em parentName="p">{`amazing`}</em>{`. The thought of quitting a decent, high paying job brings out this fear: "will I ever have something this good again?". It's really the same fear as the age one: "you've reached your peak, it's only downhill from here." It can be hard to give up the easy, comfortable life.`}</p>
    <p>{`All our reasons not to try really just boil down to fear. The nice thing about fear is you're already fully equiped to obliterate it. You just need to know how.`}</p>
    <h2>{`How to overcome fear`}</h2>
    <p>{`Here's the formula I used for overcoming all of these fears and making the leap to do my own thing:`}</p>
    <div className="formula">
  1. Name the fear <br />
  2. Find the Guiding Star <br />
  3. Sail towards it
    </div>
    <h3>{`1. Name the fear`}</h3>
    <p>{`The first step is to acknowledge the fear. Don't pretend it doesn't exist or try to downplay it. It's real and it's crippling you like a three-year-old clinging to your leg as you try to walk. It helps to `}<strong parentName="p">{`write down the fear`}</strong>{`. To see it on a piece of paper and be like "yep, that's what I'm worried about". As a super personal example, this one's been my biggest fear:`}</p>
    <blockquote>
      <p parentName="blockquote">{`I'm afraid I won't make enough money to keep doing what I love.`}</p>
    </blockquote>
    <p>{`Once you write down the fear, it helps to `}<strong parentName="p">{`give it a name`}</strong>{`. I call this particular one the good old `}<em parentName="p">{`"cash anxiety"`}</em>{`. Once something has a name/label it becomes easier to recognize when you're being influenced by it. Instead of a vague cloud of worry keeping you from feeling excited you can pinpoint it and say "oh yeah, I'm feeling the cash anxiety right now".`}</p>
    <p>{`Naming your fear/emotion is one of the most useful aspects of mindfulness:`}</p>
    <blockquote>
      <p parentName="blockquote">{`There is a simple technique for self-regulation called “affect labeling,” which simply means labeling feelings with words. When you label an emotion you are experiencing (for example, “I feel anger”), it somehow helps you manage that emotion.`}<br /><br parentName="p"></br>{`
`}{`— Chade-Meng Tan, Search Inside Yourself`}</p>
    </blockquote>
    <p>{`I wonder if Chade-Meng Tan realized how `}<em parentName="p">{`meta`}</em>{` it is that he named the act of naming things. In any case, "affect labeling" is powerful and gives you a solid pin to spear right into the center of your fear.`}</p>
    <h3>{`2. Find the guiding star`}</h3>
    <p>{`Once you've clearly identified the fear and given it a name, the next step is to imagine a situation where that fear is completely nonexistent. A scenario in which it would be downright silly for that fear to try its tricks on you. For example if you're worried about having a good enough idea, imagine a scenario where you're getting `}<em parentName="p">{`daily emails`}</em>{` from actual paying customers raving about how your product/idea improves their lives. If you're worried about money imagine yourself making 30k a month doing what you love. Don't use that S.M.A.R.T. goal garbage - right now you want to `}<strong parentName="p">{`paint for yourself the opposite picture from what your fear is telling you`}</strong>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`You must set targets that are 10 times what you think you want and then do 10 times what you think it will take to accomplish those targets. Massive thoughts must be followed by massive actions. `}<br /><br parentName="p"></br>{`
`}{`— Grant Cardone, The 10X Rule`}</p>
    </blockquote>
    <p>{`This ideal scenario - as lofty and crazy as it might sound to your friends when you tell them - is a place where your fear simply can't exist. This scenario is now your new `}<em parentName="p">{`Guiding Star`}</em>{`. Write it down right next to the fear. Associate it in your mind with the fear, so that any time you feel the fear you are immediately reminded of the Guiding Star you're working towards.`}</p>
    <h3>{`3. Sail towards it`}</h3>
    <p>{`Once you've named your fear and know what the exact opposite - your guiding star - looks like, it's time to start sailing towards it. This is the most important step. `}<strong parentName="p">{`Action is the cure to fear`}</strong>{`. And worrying isn't going to help - it just feeds your fear. Same with waiting. The only way through the fear is to take action towards that guiding star. You won't get there overnight. Or in a month and probably not even in a year. When you arrive doesn't really matter so don't try to `}<a parentName="p" {...{
        "href": "/post/estimate-becomes-deadline/"
      }}>{`force it with deadlines`}</a>{`. Instead, set little goals that lead you one at a time towards the gigantic goal. You'll get to celebrate tiny wins along the way.`}</p>
    <p>{`My main Guiding Star right now is to build up my audience of UI devs. I'm doing it by helping UI nerds like myself master UI tech, so they can build amazing stuff and land the best jobs. With a massive goal of 10,000 devs taped to my wall I decided I was done standing on the edge of the cliff with my wing suit strapped to my back. I took deep breath and jumped! I quit my job and created `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{` - a free Mastery Game where you shoot zombies with a crossbow controlled by flexbox. I had probably been watching too much Walking Dead when I came up with the idea. Anyway, even though my guiding star is 10,000 students my first little goal was just 100. I was absolutely `}<em parentName="p">{`elated`}</em>{` when I hit it! So I set a new little goal of 500 students. My kids cheered and celebrated with me when I reached it (they really have no idea what it means but they like the zombies). So on to 1,000. Then 2,000. Then 5,000. Then 7,000. I've recently passed 18,000 and am going strong. Hitting these smaller goals along the way to my guiding star is making all the difference.`}</p>
    <p>{`Don't feel like you need to map out every little goal in between where you're at now and where you want to end up. You only need to come up with the one next small goal that moves you a little closer to your Guiding Star. Each time you reach a little goal you'll have a better idea what your next one should be.`}</p>
    <p>{`Following these steps has helped me overcome a ton of fears and finally take my shot at doing my own thing. If you're wanting to do the same I hope this helps, and I'd love to hear your story. My `}<a parentName="p" {...{
        "href": "https://twitter.com/geddski"
      }}>{`twitter`}</a>{` direct messages are always open.`}</p>
    <h2>{`Challenge`}</h2>
    <p>{`First `}<strong parentName="p">{`decide`}</strong>{` whether you really want to do your own thing. If you do, then write down a `}<strong parentName="p">{`list of fears`}</strong>{`/reasons why you're not already doing it or making progress towards it. Give each fear a `}<strong parentName="p">{`name`}</strong>{`. For your biggest fear write down and tape your wall a new `}<strong parentName="p">{`Guiding Star`}</strong>{` - a gigantic goal that would obliterate that fear. Set your first `}<strong parentName="p">{`small goal`}</strong>{` that moves you one step closer to that destination and `}<strong parentName="p">{`take action`}</strong>{` on it `}<strong parentName="p">{`today`}</strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      